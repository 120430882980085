import React from 'react';
import {
  HomeWrap,
  HeaderWrap,
  ContentWrap,
  LeftPanel,
  RightPanel,
  RowWrap,
  SectionDescription,
  TutorialsWrap,
  TutorialsDisclaimer,
  Image,
  BorderedBox,
  Link,
} from './styles';
import SlaSection from './SlaSection/SlaSection';
import DashboardSection from './DashboardSection/DashboardSection';
import { Button, ButtonTypes } from '@common/components';
import NewsSection from './NewsSection/NewsSection';
import theme from '@common/theme';
import { useFeatureFlags } from '@hooks/useFeatureFlags';
import { newsItems, oldSlaItems, newSlaItems, tutorialItems } from './constants';
import { Highlight } from '@common/styles';

const Home = () => {
  const { showChristmasDeadlines, enableNiDecommissioning} = useFeatureFlags();
  // comparing to length to avoid evaluation at compile time
  const platformText =
    (process.env.REACT_APP_PLATFORMNAME ?? '').length !== 0
      ? `${process.env.REACT_APP_PLATFORMNAME?.toLowerCase()} platform for the `
      : '';
  const headerText = (process.env.REACT_APP_HEADERTEXT ?? '').length !== 0 ? <p>{process.env.REACT_APP_HEADERTEXT}</p> : '';

  return (
    <HomeWrap>
      <HeaderWrap>
        <h2>
          Welcome to your <span>{platformText}</span>new emc publishing tool
        </h2>
        {headerText}
        <p>
          The emc publishing platform is part of your emc regulatory solution, and is where you can submit your medicines
          information content for publication on emc.
        </p>
      </HeaderWrap>
      <ContentWrap>
        <LeftPanel>
        {!enableNiDecommissioning && (
          <DashboardSection>
            <Button
              type={ButtonTypes.BORDER_CONTENT}
              showArrowPostfix={true}
              height={60}
              borderColor={theme.colors.mediumGreen}
              borderHoverColor={theme.elements.primaryMedium}
              onClick={() => window.open(process.env.REACT_APP_DMS_URL, '_blank')}
            >
              GB/UK submissions: Please complete any in-progress submissions by&nbsp;<Highlight>clicking here</Highlight>
            </Button>
            <Button
              type={ButtonTypes.BORDER_CONTENT}
              showArrowPostfix={true}
              height={60}
              borderColor={theme.colors.mediumGreen}
              borderHoverColor={theme.elements.primaryMedium}
              onClick={() => window.open(process.env.REACT_APP_PUBLISHER_NI_URL, '_blank')}
            >
              NI submissions: for new and in-progress submissions, please&nbsp;<Highlight>click here</Highlight>
            </Button>
          </DashboardSection>)}
          <RowWrap>
            <NewsSection title="What's new" newsItems={newsItems} />
            <DashboardSection title="Did you know?">
              <BorderedBox>
                <h3>Datapharm becomes a certified B Corporation</h3>
                <SectionDescription>
                  Datapharm is delighted to have joined a growing list of companies which have achieved certified B Corp status.{' '}
                  <Link target="_blank" href="https://www.datapharm.com/resource-hub/datapharm-becomes-a-certified-b-corp/">
                    Read more {'>'}
                  </Link>
                </SectionDescription>
                <Image src="datapharm-b-corporation.webp" alt="datapharm-website" />
              </BorderedBox>
            </DashboardSection>
          </RowWrap>
        </LeftPanel>
        <RightPanel>
          <DashboardSection title="Tutorials">
            <TutorialsWrap>
              {tutorialItems.map((x, i) => {
                return (
                  <Button
                    key={'tutorial-' + i}
                    type={ButtonTypes.BORDER_CONTENT}
                    showArrowPostfix={true}
                    height={60}
                    icon={'/icons/gray_tick.svg'}
                    onClick={() => window.open(x.link, '_blank')}
                  >
                    {x.name}
                  </Button>
                );
              })}
            </TutorialsWrap>
            <TutorialsDisclaimer>
              For more help and tutorials visit the{' '}
              <Link target="_blank" href={process.env.REACT_APP_HELP_URL}>
                emc Academy
              </Link>
            </TutorialsDisclaimer>
          </DashboardSection>
          <SlaSection slaItems={showChristmasDeadlines ? newSlaItems : oldSlaItems} />
        </RightPanel>
      </ContentWrap>
    </HomeWrap>
  );
};
export default Home;
