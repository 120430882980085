import React, { useMemo, useState } from 'react';
import * as Styled from './styles';
import { getSections } from './helpers';
import { NoContentError } from '@common/components/NoContentError';
import { DocumentState, IDocumentDto } from '@common/features/submission/types';
import { Id } from '@common/types';
import { DocumentType } from '@common/types';
import { Sidebar } from '@common/components/Sidebar/Sidebar';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { AssociatedContentTable } from './AssociatedContentTable/AssociatedContentTable';
import { ISidebarSection } from '@common/components/Sidebar/types';
import { AssociatedContentReadonlySections } from '../../types';

interface IAssociatedContent {
  documents: IDocumentDto[];
  showMissingAssociationWarning: boolean;
  showRetiredProductWarning: boolean;
  disabledSections?: AssociatedContentReadonlySections;
  onSelect: (documentId: number, type: DocumentType) => void;
  onFileOpen?: (documentId: Id) => void;
  onDocumentTitleClick?: (documentId: Id) => void;
}

const AssociatedContent = ({
  documents,
  showMissingAssociationWarning,
  showRetiredProductWarning,
  disabledSections,
  onFileOpen,
  onSelect,
  onDocumentTitleClick,
}: IAssociatedContent) => {
  const featureFlags = useFeatureFlags();
  const sections = getSections({
    showMissingAssociationWarning,
    showRetiredProductWarning,
    featureFlags,
    disabledSections,
  });

  const [type, setType] = useState(DocumentType.Smpc);
  const displayedSection = sections.find((s) => s.additionalInformation === type);

  const handleSectionClick = (section: ISidebarSection<DocumentType>) => {
    setType(section.additionalInformation ?? DocumentType.Smpc);
  };

  let requiredTypes = useMemo(() => [type], [type]);
  if (type === DocumentType.AudioVideo && !disabledSections?.secondaryDocumentsSelection) {
    requiredTypes = [DocumentType.Audio, DocumentType.Video];
  }

  const documentsOfType = useMemo(() => documents.filter((d) => requiredTypes.includes(d.type) && d.state !== DocumentState.Retired), [documents, requiredTypes]);

  const sectionsWithPilSmpcErrors = sections.map((s) => ({
    ...s,
    showWarning: s.requiresRetirementReason && s.showWarning,
  }));

  return (
    <>
      <Styled.TabContentWrap>
        <Styled.Heading>
          <Styled.TabTitle>Associated content</Styled.TabTitle>
          <Styled.HeadingSubtext>Use the options below to manage the content associated to the product</Styled.HeadingSubtext>
        </Styled.Heading>
        <Styled.Content>
          <Sidebar
            sections={sectionsWithPilSmpcErrors}
            displayedSection={displayedSection!}
            onSectionClick={handleSectionClick}
          />
          <Styled.Wrapper>
            {displayedSection!.showWarning && displayedSection?.requiresRetirementReason && (
              <NoContentError text="Please associate a SmPC or PiL to this product" />
            )}
            {displayedSection!.showWarning && !displayedSection?.requiresRetirementReason && (
              <NoContentError text="Unable to associate, product is retired." />
            )}
            <AssociatedContentTable
              documents={documentsOfType}
              type={type}
              disableContentSelection={displayedSection?.disable}
              disableUpdateDocumentTitle={disabledSections?.editDocumentName}
              onSelect={onSelect}
              onFileOpen={onFileOpen}
              onDocumentTitleClick={onDocumentTitleClick}
            />
          </Styled.Wrapper>
        </Styled.Content>
      </Styled.TabContentWrap>
    </>
  );
};

export default AssociatedContent;
